<template>
  <div class="go-live-container">
    <!-- Video Setup Box -->
    <div class="video-setup">
      <h2>Welcome back, [Username]</h2>
      <div class="camera-section">
        <label>Camera</label>
        <div class="camera-preview">
          <div v-if="!cameraAccess" class="request-permission-overlay">
            <p>We need permission to use your camera and microphone to proceed. When prompted by the browser, please click "Allow."</p>
            <button @click="requestPermissions">Request Device Permissions</button>
          </div>
          <video v-if="cameraAccess" ref="cameraFeed" autoplay muted></video>
        </div>
      </div>
      <div class="resolution-section">
        <label>Resolution</label>
        <select v-model="selectedResolution">
          <option>720p</option>
          <option>1080p</option>
        </select>
      </div>
      <div class="video-section">
        <label>Video: </label>
        <select v-model="selectedVideoDeviceId">
          <option v-for="device in videoDevices" :key="device.deviceId" :value="device.deviceId">{{ device.label }}</option>
        </select>
      </div>
      <div class="microphone-section">
        <label>Microphone: </label>
        <select v-model="selectedMicrophoneDeviceId">
          <option v-for="device in microphones" :key="device.deviceId" :value="device.deviceId">{{ device.label }}</option>
        </select>
      </div>
      <div class="input-level">
        <label>Input Level</label>
        <input type="checkbox" v-model="isMuted" /> Mute
      </div>
      <button class="start-broadcasting" @click="toggleBroadcast"> {{ isBroadcasting ? "End Broadcasting" : "Start Broadcasting" }}</button>
      <p class="recommendation">For high quality streams we strongly recommend: <a href="#">Use external software (OBS)</a></p>
    </div>

    <!-- Chat Section Box -->
    <div class="chat-section">
      <h2>Chat</h2>
      <div class="chat-messages">
        <div class="chat-message" v-for="message in chatMessages" :key="message.id">
          <span class="chat-username">{{ message.username }}:</span>
          <span class="chat-text">{{ message.text }}</span>
        </div>
      </div>
      <input type="text" class="chat-input" placeholder="Type a message..." v-model="newMessage" @keypress.enter="sendMessage" />
      <button @click="sendMessage" class="send-message">Send</button>
    </div>
  </div>
</template>

<script>
import { firestore } from "@/firebase"; 
import { createClient, createMicrophoneAndCameraTracks } from "agora-rtc-sdk-ng";

export default {
  data() {
    return {
      APP_ID: '5825e41b14f74d83be0c6aa0d4be0f78', // Replace with your actual App ID
      TOKEN: '007eJxTYOCbs83aV+WiqUHNhy7da5p3JqpNExHW3lYlrbJQk/eC6lMFBlMLI9NUE8MkQ5M0c5MUC+OkVINks8REgxQTICvN3MJ3u3p6QyAjw4WgdYyMDBAI4nMz+Bek5gUUVyZnZCYzMAAAToMfpg==', // Replace with a valid token
      videoDevices: [],
      localTracks: [],
      cameraAccess: false,
      microphones: [],
      isMuted: false,
      chatMessages: [],
      newMessage: '',
      streamId: null,
      client: null,
      isBroadcasting: false,
    };
  },
  methods: {
    async getToken(channelName, uid) {
      try {
        const response = await fetch('/api/generateToken', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ channelName, uid })
        });
        const data = await response.json();
        if (response.ok) {
          this.token = data.token;
          console.log('Token received:', this.token);
        } else {
          console.error('Error:', data.error);
        }
      } catch (error) {
        console.error('Failed to fetch token:', error);
      }
    },
    async toggleBroadcast() {
      if (this.isBroadcasting) {
        await this.endBroadcast();
      } else {
        await this.startBroadcast();
      }
    },
    async startBroadcast() {
      await this.getToken(this.channelName, this.uid);

      if (!this.client) {
        this.client = createClient({ mode: "live", codec: "h264" });
        await this.client.initialize(this.APP_ID);
        console.log("Client initialized");
        console.log(this.client); // Should log the Agora client object
      }

      if (this.token) {
        try {
          await this.client.setClientRole("host");
          this.streamId = `stream-${Date.now()}`;
          const uid = await this.client.join(this.APP_ID, this.streamId, this.token, null);
          console.log(`User joined channel: ${this.streamId} with uid: ${uid}`);

          [this.localTracks[0], this.localTracks[1]] = await createMicrophoneAndCameraTracks(this.selectedMicrophoneDeviceId, this.selectedVideoDeviceId);
          await this.client.publish(this.localTracks);
          console.log('Local tracks published');
          this.localTracks[1].play(this.$refs.cameraFeed);
          await firestore.collection("liveStreams").doc(this.streamId).set({
            userId: "user123",
            username: "@PsychicJohn",
            isLive: true,
            streamId: this.streamId,
            timestamp: Date.now(),
          });

          this.isBroadcasting = true;
        } catch (error) {
          console.error("Error starting broadcast:", error);
        }
      } else {
        console.error('Token not received. Cannot start broadcast.');
      }
    },
    async endBroadcast() {
      if (this.localTracks.length > 0) {
        this.localTracks.forEach(track => {
          track.stop();
          track.close();
        });
        await this.client.unpublish(this.localTracks);
        this.localTracks = [];
      }
      await this.client.leave();
      console.log("Left channel");
      this.isBroadcasting = false;
      if (this.streamId) {
        await firestore.collection("liveStreams").doc(this.streamId).update({ isLive: false });
      }
    },
    async requestPermissions() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
        this.cameraAccess = true;
        this.$refs.cameraFeed.srcObject = stream; 
      } catch (error) {
        console.error("Error accessing camera or microphone:", error);
      }
    },
    async loadDevices() {
      const devices = await navigator.mediaDevices.enumerateDevices();
      this.videoDevices = devices.filter(device => device.kind === 'videoinput');
      this.microphones = devices.filter(device => device.kind === 'audioinput');
      if (this.microphones.length > 0) {
        this.selectedMicrophoneDeviceId = this.microphones[0].deviceId;
      }
      if (this.videoDevices.length > 0) {
        this.selectedVideoDeviceId = this.videoDevices[0].deviceId;
      }
    },
    sendMessage() {
      if (this.newMessage.trim()) {
        this.chatMessages.push({
          id: Date.now(),
          username: "You",
          text: this.newMessage.trim(),
        });
        this.newMessage = '';
      }
    },
  },
  async mounted() {
    await this.loadDevices();
    await this.requestPermissions();
  },
  beforeUnmount() {
    this.endBroadcast();
  },
};
</script>

<style scoped>
.camera-section {
  margin-bottom: 20px;
}

.camera-preview {
  position: relative;
  width: 100%;
  height: 250px;
  border: 2px solid #ddd;
  border-radius: 12px;
  overflow: hidden;
  background-color: #333; /* Fallback background color */
  display: flex;
  align-items: center;
  justify-content: center;
}

.go-live-container {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  margin-top:60px ;
}

.video-setup,
.chat-section {
  margin: 10px;
  width: 48%; /* Split width between the two boxes */
  padding: 20px;
  background-color: #ffffff;
  color: #f0f0f0;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}

.video-setup h2,
.chat-section h2 {
  font-size: 1.2em;
  margin-bottom: 15px;
  color: #000000;
}

.camera-section,
.resolution-section,
.video-section,
.microphone-section,
.input-level {
  padding: auto;
  margin-bottom: 15px;
}

label {
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
  color: #9fa3a9;
}

.request-permission-overlay {
  text-align: center;
  color: #f0f0f0;
}

.request-permission-overlay p {
  margin-bottom: 10px;
  font-size: 0.9em;
}

.request-permission-overlay button,
.start-broadcasting,
.send-message {
  width: 100%;
  background-color: #3D005A;
  color: #fff;
  border: none;
  padding: 10px 0;
  border-radius: 5px;
  font-size: 1em;
  cursor: pointer;
}

.request-permission-overlay button:hover,
.start-broadcasting:hover,
.send-message:hover {
  background-color: #ff5c5c;
}

.recommendation {
  font-size: 0.8em;
  color: #9fa3a9;
  text-align: center;
  margin-top: 10px;
}

.recommendation a {
  color: #3D005A;
  text-decoration: none;
}

.recommendation a:hover {
  text-decoration: underline;
}

/* Chat Section */
.chat-messages {
  max-height: 300px;
  overflow-y: auto;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #2a2f3a;
  border-radius: 5px;
}

.chat-message {
  margin-bottom: 8px;
}

.chat-username {
  font-weight: bold;
  color: #3D005A;
}

.chat-text {
  color: #f0f0f0;
}

.chat-input {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #444b57;
  border-radius: 5px;
  background-color: #2a2f3a;
  color: #f0f0f0;
}

</style>
  