<template>
  <div class="main-content">
    <!-- Category Wheel - Show only on the home page -->
    <div v-if="!selectedPsychic" class="category-wheel-container">
      <div class="category-wheel" ref="categoryWheel">
        <div
          v-for="(category, index) in categories"
          :key="index"
          class="category-item"
          @click="selectCategory(category)"
        >
          {{ category }}
        </div>
      </div>
    </div>

    <!-- Show 'No Psychics Available' Message if there are no psychics -->
    <div v-if="noPsychicsAvailable" class="no-psychics-message">
      There are currently no psychics available. Please check another category.
    </div>

    <!-- Conditionally render either the psychic list or detailed view -->
    <PsychicModule
      v-if="!noPsychicsAvailable && !selectedPsychic"
      :psychics="filteredPsychics"
      @select="selectPsychic"
    />

    <PsychicExpandedCard
      v-if="selectedPsychic"
      :psychic="selectedPsychic"
      @back="deselectPsychic"
    />
  </div>
</template>

<script>
import { firestore } from '@/firebase'; // Ensure this correctly references your Firestore instance
import { collection, getDocs, query, where } from 'firebase/firestore';
import PsychicModule from '@/components/PsychicModule.vue';
import PsychicExpandedCard from '@/components/PsychicExpandedCard.vue';
import { EventBus } from '../Eventbus.js'; // Adjust the import path

export default {
  components: {
    PsychicModule,
    PsychicExpandedCard,
  },
  mounted() {
    EventBus.on('search', this.updateSearchQuery); // Listen for the search event
    this.fetchAllPsychics();
  },
  beforeUnmount() {
    EventBus.off('search', this.updateSearchQuery); // Clean up the listener
  },
  data() {
    return {
      categories: [
        "All",
        "General Readings",
        "Tarot Readings",
        "Lingering Spirits",
        "Fortune Telling",
        "Palm Readings",
        "Astrology",
        "Dream Interpretations",
        "Past Lives",
        "Love & Relationships",
      ], // Example categories
      selectedPsychic: null, // Initialize selectedPsychic
      showLogin: false,
      showSignup: false,
      isLoggedIn: false, // This can be updated based on actual auth state
      profileImageUrl: require('@/assets/openpsychiclogo.png'), // Placeholder image URL
      psychics: [], // Psychic list from Firebase
      noPsychicsAvailable: false, // Flag to track availability
      searchQuery: '', // Added searchQuery to data
      
    };
  },
  computed: {
    filteredPsychics() {
      // Normalize the search query for better matching
      const normalizedQuery = this.searchQuery.toLowerCase().trim();

      // Check if the search query is empty; if so, return all psychics
      if (!normalizedQuery) {
        return this.psychics;
      }

      // Split the normalized query into individual words
      const queryWords = normalizedQuery.split(/\s+/);

      // Define the filtering function
      const filterPsychic = (psychic) => {
        // Normalize the psychic's name
        const userName = psychic.userName.toLowerCase();
        const firstName = psychic.firstName.toLowerCase();
        const lastName = psychic.lastName.toLowerCase();
        const fullName = `${firstName} ${lastName}`;

        // Check if any query word matches the psychic's username or name
        return queryWords.some((queryWord) =>
          userName.includes(queryWord) ||
          firstName.includes(queryWord) ||
          lastName.includes(queryWord) ||
          fullName.includes(queryWord)
        );
      };

      // Filter the psychics array using the defined filtering function
      return this.psychics.filter(filterPsychic);
    },
  },
  methods: {
    updateSearchQuery(query) {
      this.searchQuery = query; // Update the search query from header
    },
    async selectCategory(category) {
      this.selectedCategory = category;
      if (category === "All") {
        await this.fetchAllPsychics();
      } else {
        await this.fetchCategoryPsychics(category);
      }
    },
    async fetchCategoryPsychics(category) {
      try {
        console.log(`Fetching psychics from path: psychicOnDisplay/${category}/psychicsOnDisplay`);

        const psychicsCollection = collection(firestore, `psychicOnDisplay/${category}/psychicsOnDisplay`);
        const snapshot = await getDocs(psychicsCollection);

        if (snapshot.empty) {
          console.warn("No psychics found in this category.");
          this.noPsychicsAvailable = true;
          this.psychics = [];
        } else {
          this.noPsychicsAvailable = false;
          this.psychics = snapshot.docs.map(doc => {
            const data = doc.data();
            return {
              id: doc.id,
              profileImage: data.profileimgsrc || "", // Default to empty string if not available
              name: data.displayname || "", // Default to empty string if not available
              bio: data.bio || "", // Default to empty string if not available
              ratings: data.psychicrating || null, // Default to null if not available
              userType: data.usertype || "", // Default to empty string if not available
              userName: data.username || "", // Default to empty string if not available
              displayImgSrc: data.displayimgsrc || "", // Added field
              email: data.email || "", // Added field
              firstName: data.firstname || "", // Added field
              lastName: data.lastname || "", // Added field
              premium: data.premium || false, // Added field, defaults to false if not available
              isOnline: data.isOnline || false, // New field for online status
              lastOnline: data.lastOnline ? data.lastOnline.toDate().toLocaleString() : 'Never', // Format last online timestamp
              psychicOnDisplay: data.psychicondisplay || false, // Added field
              psychicOnDisplayCategory: data.psychicondisplaycategory || "", // Added field
              psychicOriginCountry: data.psychicorigincountry || null, // Added field
              psychicOriginYear: data.psychicoriginyear || null, // Added field
              questionsAnswered: data.questionsAnswered || 0, // Added field
              questionsAsked: data.questionsAsked || 0, // Added field
              requestCount: data.requestcount || 0 // Added field
            };
          });
        }
      } catch (error) {
        console.error("Error fetching psychics:", error);
        this.noPsychicsAvailable = true;
      }
    },
    selectPsychic(psychic) {
      this.selectedPsychic = psychic; // Set the selected psychic
    },
    deselectPsychic() {
      this.selectedPsychic = null; // Deselect the psychic and return to the list
    },
    scrollLeft() {
      const container = this.$refs.contactWheel;
      container.scrollBy({ left: -100, behavior: 'smooth' });
    },
    scrollRight() {
      const container = this.$refs.contactWheel;
      container.scrollBy({ left: 100, behavior: 'smooth' });
    },
    async fetchAllPsychics() {
      try {
        // Fetch psychics collection from Firestore
        const psychicsCollection = collection(firestore, 'users');
        const q = query(psychicsCollection, where('usertype', '==', 'psychic')); // Query to filter psychics
        const snapshot = await getDocs(q);

        // Map through the fetched documents and filter
        const psychicsData = snapshot.docs.map(doc => {
          const data = doc.data();
          return {
            id: doc.userid || doc.id, // Use doc.userid or fallback to doc.id
            profileImage: data.profileimgsrc || "", // Default to empty string if not available
            name: data.displayname || "", // Default to empty string if not available
            bio: data.bio || "", // Default to empty string if not available
            ratings: data.psychicrating || null, // Default to null if not available
            userType: data.usertype || "", // Default to empty string if not available
            userName: data.username || "", // Default to empty string if not available
            displayImgSrc: data.displayimgsrc || "", // Added field
            email: data.email || "", // Added field
            firstName: data.firstname || "", // Added field
            lastName: data.lastname || "", // Added field
            premium: data.premium || false, // Added field, defaults to false if not available
            psychicOnDisplay: data.psychicondisplay || false, // Added field
            psychicOnDisplayCategory: data.psychicondisplaycategory || "", // Added field
            psychicOriginCountry: data.psychicorigincountry || null, // Added field
            psychicOriginYear: data.psychicoriginyear || null, // Added field
            questionsAnswered: data.questionsAnswered || 0, // Added field
            questionsAsked: data.questionsAsked || 0, // Added field
            requestCount: data.requestcount || 0 // Added field
          };
        });

        // Update psychics array with the filtered data
        this.psychics = psychicsData;
        this.noPsychicsAvailable = this.psychics.length === 0; // Check if there are no psychics available
      } catch (error) {
        console.error("Error fetching psychics:", error);
        this.noPsychicsAvailable = true; // Set noPsychicsAvailable to true if there's an error
      }
    },
  },
};
</script>

<style scoped>
/* Your styles here */
.main-content {
  /* Example styles for the main content */
  margin-top: 20px;
}

.no-psychics-message {
  /* Styles for the 'No Psychics Available' message */
}

.spacer {
  height: 1000px; /* Adjust based on your header height */
  width: 100%;
}

.logo img {
  height: 50px;
}

.profile-bubble {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
}

.profile-bubble img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

Contact Wheel
.contact-wheel-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 80px; /* Adjust based on header height */
  position: relative;
  scrollbar-width: none;
}

.contact-wheel {
  display: flex;
  overflow-x: scroll;
  scroll-behavior: smooth;
  padding: 0 0;
  max-width: 100%; /* Adjust width */
  -ms-overflow-style: none; /* Hide scrollbar in IE and Edge */
}

.contact-wheel::-webkit-scrollbar {
  display: none; /* Hide scrollbar in Chrome, Safari, and Opera */
}

.contact-item {
  flex: 0 0 auto;
  margin: 0 10px;
  text-align: center;
}

.contact-image {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 2px solid #6a0dad;
}

.scroll-btn {
  background: none;
  border: none;
  color: #6a0dad;
  font-size: 30px;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.left-btn {
  left: 10px;
}

.right-btn {
  right: 10px;
}

.scroll-btn:hover {
  color: #50108a;
}

.category-wheel {
  display: flex;
  justify-content: space-evenly;
  gap: 8px; /* Spacing between categories */
  width: 100%;
  max-width: 100%;
  white-space: nowrap;
  overflow-x: auto;
  scrollbar-width: none;

}

.category-wheel-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  scrollbar-width: none;
  overflow-x: auto;
  /* Styles for the category wheel */
}

.category-item {
  padding: 6px 10px;
  font-size: 12px;
  color: white;
  background-color: #6a0dad;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
  transition: background-color 0.3s;
}

.category-item:hover {
  background-color: #50108a;
}

/* Optional styling for a smooth horizontal scroll */
.category-wheel-container::-webkit-scrollbar {
  height: 8px;
}
.category-wheel-container::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 10px;
}
.category-wheel-container::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
</style>
