<template>
     <!-- Section Title -->
    <h2 class="section-title">Explore Psychic Advisor's</h2>
 <div class="psychic-list">

    <PsychicCard
      v-for="psychic in psychics"
      :key="psychic.id"
      :psychic="psychic"
      class="psychic-item"
      @click="selectPsychic(psychic)"
    />
  </div>
</template>

<script>
import PsychicCard from '@/components/PsychicCard.vue';

export default {
  name: 'PsychicModule',
  components: {
    PsychicCard,
  },
  props: {
    psychics: {
      type: Array,
      required: true,
    },
  },
  methods: {
    selectPsychic(psychic) {
      this.$emit('select', psychic); // Emit selected psychic
    }
  }
};
</script>

<style scoped>
.psychic-list {
  margin-bottom: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center the cards */
  gap: 20px; /* Space between cards */
}
.psychic-card {
  background-color: #fff; /* Card background color */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Shadow effect */
  width: calc(33.33% - 20px); /* 3 cards per row, accounting for gap */
  box-sizing: border-box; /* Ensure padding is included in total width */
}

/* Responsive Design */
@media (max-width: 768px) {
  .psychic-card {
    width: calc(50% - 20px); /* 2 cards per row on medium screens */
  }
}

@media (max-width: 480px) {
  .psychic-card {
    width: calc(100% - 20px); /* 1 card per row on small screens */
  }
}

</style>
