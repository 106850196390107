// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import Dashboard from '@/components/DashboardModel.vue'; // Correct import path and naming
import GoLiveModule from '../components/GoLiveModule.vue';
import LivePsychicModule from '../components/LivePsychicModule.vue';

const routes = [
  { path: '/', redirect: '/dashboard' }, // Redirect to Dashboard by default    
  { path: '/explore', redirect: '/dashboard' }, // Redirect to Dashboard by default    
  { path: '/dashboard', component: Dashboard }, // Route for the Dashboard page
  { path: '/live', component: LivePsychicModule }, // Route for the Live Psychics page
  { path: '/go-live', name: 'GoLive', component: GoLiveModule,} //meta: { requiresAuth: true }} // Ensure only authorized users can access
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
