<template>
  <header class="header">
    <div class="logo-search-wrapper">
      <!-- Search bar and location selector -->
      <div class="search-wrapper">
        <input
          type="text"
          class="search-bar"
          placeholder="Search Psychic Advisor's..."
          v-model="searchQuery"
          @input="onSearch"
        />
      </div>
      <div class="location-box">
        <select v-model="selectedCountry" @change="onCountryChange" class="country-dropdown">
          <option value="" disabled selected>Select a country</option>
          <option v-for="country in countries" :key="country.code" :value="country.code">
            {{ country.name }}
          </option>
        </select>
      </div>
    </div>

    <nav class="nav">
      <button class="go-live-button" @click="goLive">Go Live</button>
      <button v-if="!isLoggedIn" class="auth-button" @click="toggleAuthModal">
        Login/Signup
      </button>
      <div v-if="isLoggedIn" class="profile-bubble">
        <img :src="profileImageUrl" alt="Profile Image" />
      </div>
    </nav>

    <!-- Modals for Login and Signup -->
    <LoginSignupModule v-if="showLogin" :isOpen="showLogin" @close="closeAuthModal" />
  </header>
</template>

<script>
import { EventBus } from '../Eventbus.js'; // Adjust the import path
import LoginSignupModule from './LoginSignupModule.vue';

export default {
  components: {
    LoginSignupModule
  },
  data() {
    return {
      isPsychic: true,
      countries: [
        { code: 'US', name: 'United States' },
        { code: 'CA', name: 'Canada' },
        { code: 'GB', name: 'United Kingdom' },
        { code: 'AU', name: 'Australia' },
      ],
      showLogin: false,
      showSignup: false,
      isLoggedIn: false,
      profileImageUrl: '@/assets/logo',
      searchQuery: '',
    };
  },
  methods: {
    goLive() {
      this.$router.push('/go-live');
    },
    toggleAuthModal() {
      this.showLogin = true;
    },
    closeAuthModal() {
      this.showLogin = false;
      this.showSignup = false;
    },
    onSearch() {
      const query = event.target.value;
      EventBus.emit('search', query);
    },
  },
};
</script>

<style scoped>
.header {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #16041E;
  color: #DB8FFF;
  box-shadow: 0px 2px 5px rgba(219, 143, 255, 0.3);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  box-sizing: border-box;
}

.logo-search-wrapper {
  display: flex;
  align-items: center;
  flex: 1;
  flex-wrap: wrap;
  gap: 10px;
}

.search-wrapper {
  flex: 1;
}

.search-bar {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  transition: border-color 0.3s;
}

.search-bar:focus {
  border-color: #DB8FFF;
  outline: none;
}

.location-box {
  margin-left: 16px;
}

.country-dropdown {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  background-color: #fff;
  color: #333;
  cursor: pointer;
  transition: border-color 0.3s;
}

.country-dropdown:focus {
  border-color: #DB8FFF;
  outline: none;
}

.nav {
  display: flex;
  align-items: center;
  gap: 10px;
}

.go-live-button, .auth-button {
  background-color: #3D005A;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.go-live-button:hover, .auth-button:hover {
  background-color: #DB8FFF;
}

.profile-bubble {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}

.profile-bubble img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Mobile responsiveness */
@media (max-width: 768px) {
  .header {
    width: 100%;

    padding: 10px;
    flex-direction: column;
    text-align: center;
  }

  .logo-search-wrapper {
    width: 100%;
    order: 1;
  }

  .nav {
    width: 100%;
    justify-content: center;
    order: 2;
    margin-top: 10px;
  }

  .search-bar, .country-dropdown {
    width: 100%;
  }

  .auth-button, .go-live-button {
    width: auto;
    padding: 8px 10px;
    font-size: 14px;
  }
}
</style>
