<template>
  <div class="modal" v-if="isOpen">
    <div class="modal-content">
      <span class="close" @click="closeModal">&times;</span>
      <h2>{{ isSignUp ? 'Sign Up' : 'Welcome Back!' }}</h2>

      <!-- Toggle between Login and Sign Up -->
      <button class="toggle-signup-btn" @click="toggleSignUp">
        {{ isSignUp ? 'Already have an account? Sign In' : 'New here? Sign Up' }}
      </button>

      <form @submit.prevent="isSignUp ? registerUser() : loginUser()" class="form">
        <!-- Email and Password fields -->
        <input type="email" v-model="email" placeholder="Enter your email" required />
        <input type="password" v-model="password" placeholder="Enter your password" required />

        <!-- Additional Sign Up Fields -->
        <div v-if="isSignUp" class="signup-fields">
             <!-- Psychic Fields -->
          <div v-if="isPsychic" class="psychic-fields">
            <input type="text" v-model="PsychicDisplayName" placeholder="Psychic Display Name" />
              <select v-model="specialty" id="specialty" class="dropdown">
              <option disabled value="">Select a Specialty</option>
              <option value="tarot">Tarot Reading</option>
              <option value="astrology">Astrology</option>
              <option value="mediumship">Mediumship</option>
              <option value="clairvoyance">Clairvoyance</option>
              <option value="dream-interpretation">Dream Interpretation</option>
              <option value="numerology">Numerology</option>
              <option value="energy-healing">Energy Healing</option>
              <option value="crystal-reading">Crystal Reading</option>
              <option value="palmistry">Palmistry</option>
              <option value="psychometry">Psychometry</option>
              <option value="spiritual-coaching">Spiritual Coaching</option>
          </select>            
          </div>
          <input type="text" v-model="Usernmae" placeholder="Username" />
          <input type="text" v-model="firstName" placeholder="First Name" required /> 
          <input type="text" v-model="lastName" placeholder="Last Name" required />
          <input type="tel" v-model="phone" placeholder="Phone Number (e.g., +123456789)" required class="phone-input" />
          <input type="date" v-model="dob" placeholder="Date of Birth" required />

          <label class="checkbox-label">
            <input type="checkbox" v-model="isPsychic" /> Sign up as Psychic
          </label>
      
        </div>

        <button type="submit" class="login-btn" @click="submitForm">{{ isSignUp ? 'Sign Up' : 'Sign In' }}</button>
        <div v-if="!isSignUp" class="forgot-password">Forgot Password?</div>
      </form>
    </div>
  </div>
</template>

<script>

import { auth } from '@/firebase'; // Adjust import based on your setup
import { createUserWithEmailAndPassword, signInWithEmailAndPassword } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '@/firebase'; // Firestore instance

export default {
  props: ['isOpen'],
  data() {
    return {
      isSignUp: false,
      isPsychic: false,
      email: '',
      password: '',
      firstName: '',
      lastName: '',
      dob: '',
      specialty: "",
      phone: '',
      errorMessage: '', // Error message for displaying errors
      showToast: false  // Controls the visibility of the toast notification
    };
  },
  methods: {
    showToastNotification() {
      this.showToast = true;
      setTimeout(() => {
        this.showToast = false; // Hide toast after 3 seconds
        this.errorMessage = ''; // Clear the error message
      }, 3000);
  },
    submitForm() {
      if (this.isSignUp) {
        this.registerUser();
      } else {
        this.loginUser();
      }
    },
    closeModal() {
      this.$emit('close');
      this.resetForm();
    },
    toggleSignUp() {
      this.isSignUp = !this.isSignUp;
    },
    resetForm() {
      this.isSignUp = false;
      this.isPsychic = false;
      this.email = '';
      this.password = '';
      this.firstName = '';
      this.lastName = '';
      this.dob = '';
      this.specialty = '';
      this.bio = '';
      this.phone = '';
    },
    async loginUser() {
      try {
        await signInWithEmailAndPassword(auth, this.email, this.password);
        this.$router.push('/dashboard');
        this.closeModal();
      } catch (error) {
        console.error("Login Error: ", error.message);
        this.errorMessage = `Login Error: ${error.message}`;
        this.showToastNotification();
      }
    },
    
    async registerUser() {
      try {
        const userCredential = await createUserWithEmailAndPassword(auth, this.email, this.password);
        const userId = userCredential.user.uid;
        
        // Determine user type and structure data accordingly
        if (this.isPsychic) {
          // Psychic-specific data structure
          const psychicData = {
            userid: userId,
            email: this.email,
            firstname: this.firstname,
            lastname: this.lastname,
            dob: this.dob,
            displayname: this.displayname,
            specialty: this.specialty,
            profileimgsrc: '',
            displayimgsrc: '',
            psychicondisplaycategory: '',
            psychicondisplay: false,
            usertype: 'psychic',
            username: this.username,
            bio: ' ',
            phone: this.phone
          };
          
          await setDoc(doc(db, 'users', userId), psychicData);
          console.log("Psychic data saved!");

        } else {
          // Regular user data structure
          const userData = {
            firstname: this.firstname,
            lastname: this.lastname,
            dob: this.dob,
            usertype: 'user',
            bio: '',
            profileimgsrc: '',
            displayimgsrc: '',
            username: this.username,
            email: this.email,
            userid: userId,
            questionsAvailable: 3,
            phone: this.phone
          };

          // Save to 'users' collection
          await setDoc(doc(db, 'users', userId), userData);
          console.log("User data saved!");
        }

        // Navigate to dashboard and close modal
        this.$router.push('/dashboard');
        this.closeModal();

      } catch (error) {
        console.error("Registration Error: ", error.message);
        this.errorMessage = `Registration Error: ${error.message}`;
        this.showToastNotification();
      }
    }
  },
};
</script>

<style scoped>

/* Toast Notification */
.toast {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #FFCD00; /* Bright yellow for high visibility */
  color: #2a093a; /* Dark purple text */
  padding: 12px 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.3);
  font-size: 1em;
  font-weight: bold;
  z-index: 1000;
  transition: opacity 0.3s ease, transform 0.3s ease;
}

.toast p {
  margin: 0;
}

.toast-enter-active, .toast-leave-active {
  opacity: 1;
  transform: translateY(0);
}

.toast-enter, .toast-leave-to {
  opacity: 0;
  transform: translateY(10px);
}

/* Modal Background */
/* Label styling to match the modal design */
.dropdown-label {
  font-size: 1em;
  color: #ECC3FF; /* Light purple text */
  display: block;
  text-align: left;
}

/* Dropdown styling for a modern, sleek look */
.dropdown {
  width: 100%;
  padding: 12px;
  border-radius: 10px;
  background-color: #2a093a; /* Matte, deep purple background */
  border: 1px solid #DB8FFF; /* Light purple border */
  color: #ECC3FF; /* Light purple text */
  font-size: 1em;
  appearance: none;
  cursor: pointer;
  transition: border 0.3s ease, background-color 0.3s ease;
}

/* Styling for options */
.dropdown option {
  color: #2a093a; /* Darker color for options */
  background-color: #ECC3FF; /* Light background for readability */
}

/* Hover and focus effects */
.dropdown:hover,
.dropdown:focus {
  background-color: #3D005A; /* Slightly darker purple on hover */
  border: 1px solid #9B4BC0; /* Accent purple on focus */
  outline: none;
}

/* Optional styling to add a custom arrow for the dropdown */
.dropdown::after {
  content: "▼";
  position: absolute;
  right: 16px;
  top: calc(50% - 0.5em);
  font-size: 0.8em;
  color: #ECC3FF; /* Light purple arrow */
  pointer-events: none;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(22, 4, 30, 0.9); /* Matte overlay for background focus */
  z-index: 1000;
  padding: 20px;
}

/* Modal Content Styling */
.modal-content {
  position: relative;
  width: 100%;
  max-width: 400px;
  background-color: #2a093a; /* Matte, deep purple */
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.3);
  text-align: center;
  color: #ECC3FF; /* Light purple text */
}

/* Close Button */
.close {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 1.5em;
  color: #DB8FFF;
  cursor: pointer;
  transition: color 0.3s ease;
}

.close:hover {
  color: #FFCD00;
}

/* Header Styling */
h2 {
  font-size: 1.6em;
  margin: 15px 0;
  font-weight: 700;
  color: #FFCD00;
}

/* Form Styling */
.form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form input,
.signup-fields input,
.psychic-fields input,
.psychic-fields textarea {
  width: 100%;
  max-width: 360px;
  padding: 14px;
  margin: 5px 0; /* Even vertical spacing */
  border-radius: 8px;
  border: 1px solid #9B4BC0;
  background-color: #3d0d57; /* Matte mid-purple */
  color: #ECC3FF;
  font-size: 1em;
  transition: border-color 0.3s ease, background-color 0.3s ease;
}

.form input:focus,
.signup-fields input:focus,
.psychic-fields input:focus,
.psychic-fields textarea:focus {
  border-color: #DB8FFF; /* Brighter purple border on focus */
  background-color: #2a093a; /* Slightly darker background on focus */
  outline: none;
}

/* Primary Button */
.login-btn {
  width: 100%;
  max-width: 360px;
  padding: 12px;
  margin-top: 20px;
  background-color: #FFCD00; /* Primary Gold */
  color: #2a093a; /* Deep Purple Text */
  font-size: 1em;
  font-weight: bold;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease, box-shadow 0.3s ease;
}

/* Hover State */
.login-btn:hover {
  background-color: #e6b800; /* Darker Gold on hover */
  transform: scale(1.02);
  box-shadow: 0 4px 10px rgba(230, 184, 0, 0.3); /* Subtle shadow for depth */
}

/* Focus State */
.login-btn:focus {
  outline: none;
  box-shadow: 0 0 8px #DB8FFF, 0 0 16px #DB8FFF; /* Purple glow for focus */
}

/* Disabled State */
.login-btn:disabled {
  background-color: #e0e0e0;
  color: #999;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

/* Secondary Button */
.cancel-btn {
  width: 100%;
  max-width: 360px;
  padding: 10px;
  margin-top: 10px;
  background-color: transparent;
  color: #DB8FFF;
  font-size: 0.9em;
  border: 1px solid #DB8FFF;
  cursor: pointer;
  transition: color 0.3s ease, border-color 0.3s ease;
}

.cancel-btn:hover {
  color: #FFCD00;
  border-color: #FFCD00;
}

/* Forgot Password Link */
.forgot-password {
  margin-top: 12px;
  font-size: 0.9em;
  color: #A6EFCEFF;
  cursor: pointer;
  text-align: center;
  transition: color 0.3s ease;
}

.forgot-password:hover {
  color: #FFCD00;
}

/* Checkbox Styling */
.checkbox-label {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 1em;
  color: #ECC3FF;
  cursor: pointer;
  justify-content: center;
}

.checkbox-label input[type="checkbox"] {
  width: 20px;
  height: 20px;
  cursor: pointer;
  accent-color: #FFCD00;
}

.checkbox-label span {
  color: #A6EFCEFF;
  transition: color 0.3s ease;
}

.checkbox-label:hover span {
  color: #FFCD00;
}

/* Responsive Design */
@media (max-width: 600px) {
  .modal-content {
    padding: 20px;
  }
  h2 {
    font-size: 1.4em;
  }
  .form input,
  .login-btn,
  .cancel-btn {
    font-size: 0.9em;
    padding: 10px;
  }
}

.toggle-signup-btn {
  background-color: transparent;
  color: #DB8FFF; /* Purple accent color for contrast */
  border: none;
  font-size: 1em;
  font-weight: bold;
  padding: 10px 20px;
  margin: 10px;
  cursor: pointer;
  text-align: center;
  transition: color 0.3s ease, background-color 0.3s ease, transform 0.2s ease;
}

/* Hover Effect */
.toggle-signup-btn:hover {
  color: #FFCD00; /* Gold accent for emphasis */
  background-color: rgba(255, 205, 0, 0.1); /* Light gold background on hover */
  transform: scale(1.02); /* Slight scale up for an interactive feel */
  border-radius: 8px;
}

/* Focused Button State */
.toggle-signup-btn:focus {
  outline: none;
  box-shadow: 0 0 5px #DB8FFF, 0 0 10px #DB8FFF; /* Purple glow for focus */
  color: #FFCD00;
}

/* Center Align in a Flex Container */
.toggle-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px; /* Adjust top margin for spacing */
}
</style>