<template>
  <footer class="footer">
    <div class="footer-content">
      <p>&copy; 2024 Open Psychic. All rights reserved.</p>
      <div class="footer-links">
        <a href="#">Privacy Policy</a>
        <span>|</span>
        <a href="#">Terms of Service</a>
        <span>|</span>
        <a href="#">Contact</a>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterModule",
};
</script>

<style scoped>
.footer {
  background-color: #16041E; /* White background for the footer */
  height: 20px; /* Set footer height */
  padding: 10px 20px; /* Padding for the footer */
  position: fixed; /* Fixed positioning for footer */
  bottom: 0; /* Position at the bottom of the parent container */
  margin-top: 10px; /* Add top margin for spacing */
  left: 0;
  width: 100%; /* Full width */
  display: flex; /* Flexbox for layout */
  justify-content: center; /* Center content */
  align-items: center; /* Center align items */
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow on top */
}

.footer-content {
  display: flex; /* Flexbox for footer content */
  justify-content: center; /* Center content */
  align-items: center; /* Center align items */
  text-align: center; /* Center text */
}

.footer-links {
  display: flex; /* Flexbox for links */
  align-items: center; /* Center align links */
  margin-left: 15px; /* Spacing between copyright and links */
}

.footer-links a {
  text-decoration: none; /* Remove underline */
  margin: 0 5px; /* Margin between links */
}

.footer-links span {
  color: #ffffff; /* White text for separator */
}
</style>
}