<template>
  <div class="psychic-card" @click="bookReading">
    <div class="psychic-image-container">
      <img :src="psychic.displayImgSrc || placeholderImage" :alt="psychic.name" class="psychic-image" @error="handleImageError" />
    </div>
    <div class="psychic-info">
      <div class="user-profile-bubble">
        <img :src="psychic.profileImage || placeholderImage" :alt="psychic.name" class="user-profile-image" @error="handleImageError" />
      </div>
      <div class="psychic-details">
        <h3 class="psychic-name">
          {{ psychic.name }}
        </h3>
        <h4 class="psychic-username">
          @{{ psychic.userName }}
        </h4>
      </div>
    </div>
    <div class="status-indicator">
      <span :class="statusClass"></span>
      <span class="status-text">{{ onlineStatus }}</span>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      placeholderImage: require('@/assets/openpsychiclogo.png') // Placeholder image
    };
  },
  props: {
    psychic: {
      type: Object,
      required: true
    }
  },
  computed: {
    statusClass() {
      const now = new Date();
      const twentyFourHoursAgo = new Date(now.getTime() - 24 * 60 * 60 * 1000); // 24 hours ago
      const lastOnlineDate = new Date(this.psychic.lastOnline); // Ensure lastOnline is a valid date

      if (this.psychic.online) {
        return 'status-online'; // Online
      } else if (this.psychic.lastOnline && lastOnlineDate >= twentyFourHoursAgo) {
        return 'status-away'; // Away
      } else {
        return 'status-offline'; // Offline
      }
    },
    onlineStatus() {
      if (this.psychic.online) {
        return 'Online';
      } else if (this.psychic.lastOnline && new Date(this.psychic.lastOnline) >= new Date(Date.now() - 24 * 60 * 60 * 1000)) {
        return 'Away'; // Last online within the last 24 hours
      } else {
        return 'Offline';
      }
    }
  },
  methods: {
    handleImageError(event) {
      // Handle image loading error, e.g., set to a placeholder image
      event.target.src = this.placeholderImage;
    },
    bookReading() {
      // Your booking logic here
      console.log('Booking reading with', this.psychic.name);
    }
  }
}
</script>

<style scoped>

.user-profile-bubble {
  width: 40px; /* Bubble width */
  height: 40px; /* Bubble height */
  border-radius: 50%; /* Circular shape */
  overflow: hidden; /* Clip the image to fit */
  display: flex; /* Center the image inside the bubble */
  justify-content: center; /* Center the image horizontally */
  align-items: center; /* Center the image vertically */
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3); /* Optional: shadow for depth */
}

.user-profile-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Maintain aspect ratio */
}

.psychic-card {
  background-color: #ffffff;
  overflow: hidden;
  transition: transform 0.2s;
  cursor: pointer;
  width: 500px; /* Fixed width for uniformity */
  position: relative; /* For positioning the status indicator */
}

.psychic-card:hover {
  transform: translateY(-5px);
}

.psychic-image-container {
  width: 100%;
  height: 200px; /* Adjust based on design */
  overflow: hidden; /* Maintain aspect ratio */
}

.psychic-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.psychic-info {
  display: grid; /* Use grid for layout */
  grid-template-columns: auto 1fr; /* Two columns: bubble and text */
  align-items: left; /* Center items vertically */
  background:#16041E; /* background */
  padding:4px;
}

.psychic-name {
  font-size: 1.2em;
  margin: 0; /* Remove margin for tighter spacing */
  color: white;
  text-align:left;
  padding-left:10px;
}

.psychic-username {
  font-size: 0.9em; /* Adjust size as necessary */
  margin: 0; /* Remove margin for tighter spacing */
  color: white; /* Style for the username */
  text-align:left;
  padding-left:10px;

}

/* 
.rating-container {
  margin-top: 10px;
}

*/

.status-indicator {
  position: absolute; /* Position it absolutely */
  top: 10px; /* Distance from the top */
  left: 15px; /* Distance from the left */
  display: flex;
  align-items: center;
  padding: 5px; /* Optional padding */
  z-index: 1; /* Ensure it's on top of other elements */
}

.status-online {
  width: 8px;
  height: 8px;
  background-color: rgb(25, 255, 25);
  border-radius: 50%;
  margin-right: 5px;
}

.status-away {
  width: 8px;
  height: 8px;
  background-color: rgb(255, 215, 0); /* Yellow color for away */
  border-radius: 50%;
  margin-right: 5px;
}

.status-offline {
  width: 8px;
  height: 8px;
  background-color: red;
  border-radius: 50%;
  margin-right: 5px;
}

.status-text {
  font-size: 0.5em;
  color: #ffffff;
}
</style>
