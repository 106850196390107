<template>
  <div class="page-container">
    <div class="psychic-profile-card">
      <!-- Header Section -->
      <div class="profile-header">
        <img :src="psychic.displayImgSrc || placeholderImage" alt="Profile Background" class="background-image" @error="handleImageError" />
        <div class="profile-info">
          <img :src="psychic.profileImage || placeholderImage" alt="Psychic Image" class="profile-image" @error="handleImageError" />
        </div>
      </div>

      <!-- Bio and Rating Section -->
      <div class="profile-body">
        <h2 class="name">{{ psychic.name || 'Unknown Psychic' }}</h2>
        <p class="username">{{ '@' + (psychic.userName || 'User') }}</p>
        <p class="bio">{{ psychic.bio || 'No bio available.' }}</p>
      </div>

      <!-- Contact Module -->
      <ContactModule class="contact-module"/>
    </div>
  </div>
</template>

<script>
import ContactModule from './ContactModule.vue';

export default {
  methods: {
    handleImageError(event) {
      event.target.src = this.placeholderImage;
    },
  },
  components: {
    ContactModule,
  },
  props: {
    psychic: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      placeholderImage: require('@/assets/openpsychiclogo.png'),
    };
  },
};
</script>

<style scoped>
.page-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f4f4f8;
  padding:2px;
}

.psychic-profile-card {
  width: 100%;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  text-align: center;
}

.profile-header {
  position: relative;
  background-color: #6a0dad;
  color: white;
  text-align: center;
}

.background-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.profile-info {
  position: absolute;
  top: 130px;
  left: 50%;
  transform: translateX(-50%);
}

.profile-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 3px solid #ffffff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.name {
  font-size: 1.5em;
  font-weight: 600;
  margin: 15px 0 5px;
  color: #16041E;
}

.username {
  font-size: 1em;
  color: #666;
  margin-bottom: 10px;
}

.profile-body {
  padding: 20px;
  font-size: 0.9em;
  color: #333;
}

.bio {
  margin: 10px 0;
  color: #555;
}
.contact-module {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 350px;
  margin: 20px auto;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.2s ease;
  
}

.contact-module:hover {
  transform: translateY(-3px); /* Subtle hover effect */
}

.contact-module h3 {
  margin-bottom: 10px;
  font-size: 1.2em;
  color: #16041E; /* Adjust color to match overall theme */
}

.contact-module p {
  font-size: 0.95em;
  color: #666;
  margin-bottom: 15px;
}

.contact-module button {
  width: 80%;
  padding: 10px;
  background-color: #6a0dad; /* Primary button color */
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 0.9em;
  font-weight: bold;
  transition: background-color 0.3s ease;
}

.contact-module button:hover {
  background-color: #5a0ca7; /* Darken button on hover */
}

strong {
  color: hsl(275, 86%, 36%);
}
</style>
