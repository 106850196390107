<template>
    <div>
      <div>
        <h3>My Video</h3>
        <video ref="myVideo" autoplay playsinline></video>
      </div>
      <div>
        <h3>Remote Video</h3>
        <video ref="remoteVideo" autoplay playsinline></video>
      </div>
  
      <button v-if="!isCalling" @click="startCall">Start Call</button>
      <button v-if="isCalling" @click="endCall">End Call</button>
    </div>
  </template>
  
  <script>
import { io } from 'socket.io-client';

  export default {
    data() {
      return {
        localStream: null,
        remoteStream: null,
        peerConnection: null,
        isCalling: false,
        signalingSocket: null, // Socket for signaling
      };
    },
    methods: {
      initiateCallToPsychic(psychicId) {
        // Initiates a call to the specified psychic by ID
        this.makeCall(psychicId);
      },
       async startLocalStream() {
        try {
          this.localStream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
          document.getElementById("localVideo").srcObject = this.localStream;
        } catch (error) {
          console.error("Error accessing media devices.", error);
        }
      },
      async setupPeerConnection() {
      
        this.peerConnection = new RTCPeerConnection();

      // Add local tracks to the peer connection
      this.localStream.getTracks().forEach(track => {
        this.peerConnection.addTrack(track, this.localStream);
      });

      // Listen for remote track events to display the remote video
      this.peerConnection.ontrack = (event) => {
        document.getElementById("remoteVideo").srcObject = event.streams[0];
      };

      // Handle ICE candidates
      this.peerConnection.onicecandidate = (event) => {
        if (event.candidate) {
          this.signalingSocket.emit("ice_candidate", {
            targetUserId: this.targetUserId,
            candidate: event.candidate,
           });
        }
       };
      },
      async makeCall(psychicId) {
        // Emit a signaling event with the caller's ID and target psychic's ID
        this.signalingSocket.emit("call", { callerId: this.tempUserId, targetUserId: psychicId });
        
        // Store the `psychicId` to use it for signaling during the session
        this.targetUserId = psychicId;

        await this.startLocalStream(); // Start local video/audio
        await this.setupPeerConnection(); // Set up WebRTC peer connection

        // Create an offer to initiate the call
        const offer = await this.peerConnection.createOffer();
        await this.peerConnection.setLocalDescription(offer);

        // Send the offer to the specified `psychicId`
        this.signalingSocket.emit("offer", { targetUserId: psychicId, offer });
      },
      async startCall() {
        this.isCalling = true;
        
        // Initialize streams and WebRTC connection
        await this.getUserMedia();
  
        this.peerConnection = new RTCPeerConnection({
          iceServers: [
            { urls: "stun:stun.l.google.com:19302" },
            { urls: "turn:YOUR_TURN_SERVER_URL", username: "YOUR_USERNAME", credential: "YOUR_PASSWORD" },
          ],
        });
  
        // Handle new ICE candidates from local peer
        this.peerConnection.onicecandidate = (event) => {
          if (event.candidate) {
            this.signalingSocket.emit("candidate", event.candidate);
          }
        };
  
        // Handle track event to receive remote stream
        this.peerConnection.ontrack = (event) => {
          this.$refs.remoteVideo.srcObject = event.streams[0];
        };
  
        // Add local stream tracks to peer connection
        this.localStream.getTracks().forEach((track) => {
          this.peerConnection.addTrack(track, this.localStream);
        });
  
        // Create offer for the call
        const offer = await this.peerConnection.createOffer();
        await this.peerConnection.setLocalDescription(offer);
        this.signalingSocket.emit("offer", offer);
      },
      async getUserMedia() {
        try {
          this.localStream = await navigator.mediaDevices.getUserMedia({
            video: true,
            audio: true,
          });
          this.$refs.myVideo.srcObject = this.localStream;
        } catch (error) {
          console.error("Error accessing media devices.", error);
        }
      },
      async answerCall(callerId) {
        this.targetUserId = callerId; // Store caller ID to keep track of the call

        await this.startLocalStream(); // Start local video/audio
        await this.setupPeerConnection(); // Set up WebRTC peer connection

        // Create an answer for the call and send it back to the caller
        const answer = await this.peerConnection.createAnswer();
        await this.peerConnection.setLocalDescription(answer);

        // Emit the answer to the caller to complete the handshake
        this.signalingSocket.emit("answer", { callerId, answer });
      },
      endCall() {
        this.peerConnection.close();
        this.localStream.getTracks().forEach(track => track.stop()); // Stop media streams
        this.peerConnection = null;
        this.localStream = null;
        this.signalingSocket.emit("end_call", { targetUserId: this.targetUserId }); // Notify other user
      }
    },
    mounted() {
      // Signaling socket setup
      this.signalingSocket = io("http://localhost:3000"); // Replace with your signaling server
  
      // Receive call offer
      this.signalingSocket.on("offer", (offer) => {
        if (!this.isCalling) {
          this.answerCall(offer);
        }
      });
  
      // Receive answer to call
      this.signalingSocket.on("answer", async (answer) => {
        await this.peerConnection.setRemoteDescription(answer);
      });
  
      // Receive new ICE candidates
      this.signalingSocket.on("candidate", async (candidate) => {
        await this.peerConnection.addIceCandidate(candidate);
      });
    },
    created() {
      this.tempUserId = `user_${Date.now()}_${Math.floor(Math.random() * 1000)}`;
  
      console.log(`Temporary User ID for this session: ${this.tempUserId}`);
      // Listener for incoming call requests with `psychicId`
      this.signalingSocket.on("incoming_call", (data) => {
        const { callerId } = data;
        if (confirm(`Incoming call from psychic ${callerId}. Accept?`)) {
          this.answerCall(callerId); // Accept the call
        } else {
          this.signalingSocket.emit("call_rejected", { callerId });
        }
  });

  // Listener for receiving an offer
  this.signalingSocket.on("offer", async (data) => {
    const { offer, callerId } = data;
    await this.peerConnection.setRemoteDescription(new RTCSessionDescription(offer));
    this.answerCall(callerId); // Respond to the offer from `callerId`
  });

  // Listener for receiving an answer
  this.signalingSocket.on("answer", async (data) => {
    const { answer } = data;
    await this.peerConnection.setRemoteDescription(new RTCSessionDescription(answer));
  });

  // Listener for receiving ICE candidates
  this.signalingSocket.on("ice_candidate", async (data) => {
    const candidate = new RTCIceCandidate(data.candidate);
    await this.peerConnection.addIceCandidate(candidate);
  });
}

  };
  </script>
  
  <style scoped>
  video {
    width: 300px;
    height: 200px;
    margin: 5px;
  }
  </style>
  