// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';          // For authentication
import { getFirestore } from 'firebase/firestore'; // For Firestore database
import { getDatabase } from 'firebase/database';    // For real-time database if needed

// Your Firebase configuration object (replace with your actual config)
const firebaseConfig = {
  apiKey: "AIzaSyB3wdykLnfjx0FJNibPh3ILCxPUP0xy0_A",
  authDomain: "open-psychic.firebaseapp.com",
  projectId: "open-psychic-143d5",
  storageBucket: "open-psychic-143d5.appspot.com",
  messagingSenderId: "null",
  appId: "1:189441166810:android:acdbb571b5b8ae114044e3"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Export Firebase services to use in the app
const auth = getAuth(app);
const firestore = getFirestore(app);
const database = getDatabase(app);

export { auth, firestore, database };
