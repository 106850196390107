<template>
    <div class="live-psychics">
      <h1>Live Psychics</h1>
      <div v-if="liveStreams.length > 0">
        <div
          v-for="stream in liveStreams"
          :key="stream.userId"
          class="stream-thumbnail"
          @click="joinStream(stream.streamId)"
        >
          <img :src="stream.thumbnail || stream.profilePicture" alt="Thumbnail" />
          <div class="stream-info">
            <h2>{{ stream.username }}</h2>
            <p>{{ stream.title }}</p>
          </div>
        </div>
      </div>
      <div v-else>
        <p>No live psychics currently streaming.</p>
      </div>
    </div>
  </template>
  
  <script>
  import { firestore } from "@/firebase"; // Adjust this path to your Firebase config
  
  export default {
    data() {
      return {
        liveStreams: []
      };
    },
    methods: {
      async fetchLiveStreams() {
        const querySnapshot = await firestore.collection("liveStreams").where("isLive", "==", true).get();
        this.liveStreams = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      },
      joinStream(streamId) {
        this.$router.push(`/live/${streamId}`);
      }
    },
    created() {
      this.fetchLiveStreams();
    }
  };
  </script>
  
  <style scoped>
  .live-psychics {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding:20px;
  }
  
  .stream-thumbnail {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
    cursor: pointer;
    text-align: center;
  }
  
  .stream-thumbnail img {
    width: 150px;
    height: 150px;
    border-radius: 8px;
    object-fit: cover;
  }
  
  .stream-info {
    margin-top: 8px;
  }
  </style>
  