<template>
  <div id="app">
    <!-- Sidebar and Header -->
    <div class="main-container">
      <LeftNav
        v-if="isSidebarVisible"
        @collapsed="handleSidebarCollapse"
        ref="sidebar"
        class="sidebar"
      />
      <HeaderModel :searchQuery="updateSearchQuery" :class="headerClass" />
    </div>

    <!-- Main Content Area -->
    <div :class="['content', { 'content-expanded': isCollapsed }]">
      <!-- router-view will render the routed component here, replacing DashboardModel if on another route -->
      <router-view />
    </div>

    <!-- Footer -->
    <FooterModule />
  </div>
</template>

<script>
import LeftNav from '@/components/LeftNav.vue';
import HeaderModel from '@/components/HeaderModel.vue';
import FooterModule from '@/components/FooterModule.vue';

export default {
  name: 'App',
  components: {
    LeftNav,
    HeaderModel,
    FooterModule
  },
  data() {
    return {
      searchQuery: '', // Store search query from header
      isSidebarVisible: true, // Default state of sidebar
    };
  },
  computed: {
    isCollapsed() {
      return this.$refs.sidebar && this.$refs.sidebar.isCollapsed;
    },
    headerClass() {
      return {
        'header-container-expanded': !this.isCollapsed,
        'header-container-collapsed': this.isCollapsed,
      };
    }
  },
  methods: {
    updateSearchQuery(query) {
      this.searchQuery = query; // Update search query when emitted from header
    },
    toggleSidebar() {
      this.isSidebarVisible = !this.isSidebarVisible; // Toggle sidebar visibility
    },
    handleSidebarCollapse(isCollapsed) {
      // Handle sidebar collapse event if needed
      console.log('Sidebar is collapsed:', isCollapsed);
    }
  }
};
</script>

<style scoped>
html, body {
  margin: 0;
  padding: 0;
  background-color: #16041E;
  width: 100vw; /* Full viewport width */
  height: 100%
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  text-align: center;
  color: #DB8FFF;
  background-color: #16041E;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%; /* Full width */
  overflow-x: hidden; /* Prevent horizontal scrolling */
  overflow-y: hidden; /* Prevent horizontal scrolling */
}

.main-container {
  display: flex;
  background-color: #16041E;
  width: 100%; /* Full width */
}

.header-container-expanded {
  margin-left: 195px;
  width: calc(100% - 195px);
}

.header-container-collapsed {
  margin-left: 70px;
  width: calc(100% - 70px);
}

.content {
  padding-top:40px;
  flex-grow: 1;
  transition: margin-left 0.3s;
  margin-left: 200px; /* Adjust based on sidebar width */
  background-color: #16041E;
}

.content-expanded {
  margin-left: 70px;
  background-color: #16041E;
}

/* Mobile Responsive Styles */
@media (max-width: 768px) {
  .main-container {
    flex-direction: column;
    width: 100%;
    overflow-x: hidden; /* Prevent horizontal scrolling */
    overflow-y: hidden; /* Prevent horizontal scrolling */
  }
  
  .header-container-expanded, .header-container-collapsed {
    margin-left: 0;
    width: 100%;
  }

  .content, .content-expanded {
    margin-top: 40px;
    margin-left: 0;
    width: 100%;
  }

  /* Show sidebar when toggled */
  .sidebar-visible .sidebar {
    display: block;
  }
}
</style>
